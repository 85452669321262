import { useState, useEffect } from 'react';

const EVENTS_TO_CAPTURE = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
];

export default function useUserIdle(isEnableState, idleTimeout) {
  const [idleTimeoutExpires, setIdleTimeoutExpires] = useState(false);
  const [idleTimeoutWarningExpires, setIdleTimeoutWarningExpires] = useState(
    false,
  );
  let idleTimeoutTimer = null;
  let idleWarningTimer = null;

  const resetIdleTimers = () => {
    clearTimeout(idleTimeoutTimer);
    clearTimeout(idleWarningTimer);
    idleTimeoutTimer = setTimeout(
      () => setIdleTimeoutExpires(true),
      idleTimeout,
    );
    idleWarningTimer = setTimeout(
      () => setIdleTimeoutWarningExpires(true),
      idleTimeout - 5000,
    );
  };

  useEffect(() => {
    setIdleTimeoutExpires(false);
    setIdleTimeoutWarningExpires(false);
    if (isEnableState) {
      resetIdleTimers();
      EVENTS_TO_CAPTURE.forEach(event =>
        window.addEventListener(event, resetIdleTimers),
      );
    }
    return () => {
      setIdleTimeoutExpires(false);
      setIdleTimeoutWarningExpires(false);
      clearTimeout(idleTimeoutTimer);
      clearTimeout(idleWarningTimer);
      EVENTS_TO_CAPTURE.forEach(event =>
        window.removeEventListener(event, resetIdleTimers),
      );
    };
  }, [idleTimeoutTimer, idleWarningTimer, isEnableState, resetIdleTimers]);

  return [idleTimeoutExpires, idleTimeoutWarningExpires];
}
