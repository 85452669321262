import React from 'react';
import './technologies.scss';
import { withRouter } from 'react-router-dom';

const Technology = ({ technologyList, history }) => {
  const setProgramming = (e, { _id }) => {
    e.preventDefault();
    history.push(`/courses/${_id}`);
  };
  return (
    <React.Fragment>
      <div className="page-section">
        <div className="page-container">
          <div className="page-body">
            <div className="flex-grid home--flex-grid">
              {technologyList &&
                technologyList.map((i, index) => {
                  return (
                    <a
                      key={index}
                      href="/"
                      className="home--flex-grid__item js-topic-grid-item"
                    >
                      <img
                        className="topic-thumbnail"
                        alt="Tutorials and Courses"
                        src={i.url}
                      />
                      <a
                        href="/"
                        onClick={e => setProgramming(e, i)}
                        className="js-topic"
                      >
                        {i.title}
                      </a>
                    </a>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Technology);
