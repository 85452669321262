import React from 'react';
import ReactDOM from 'react-dom';
import Routing from './routing';
import './assets/scss/index.scss';
import ConfigCtxProvider from './HOCs/configCtx';
import MenuCtxProvider from './HOCs/menuCtx';

import Apollo from './HOCs/apollo';
import TransalationProvider from './HOCs/transaltionCtx';
import ContainerCtx from './HOCs/containerCtx';
import { ApolloNetworkStatusProvider } from 'react-apollo-network-status';

function Root() {
  return (
    <ContainerCtx>
      <ConfigCtxProvider>
        <TransalationProvider>
          <MenuCtxProvider>
            <Apollo>
              <ApolloNetworkStatusProvider>
                <Routing />
              </ApolloNetworkStatusProvider>
            </Apollo>
          </MenuCtxProvider>
        </TransalationProvider>
      </ConfigCtxProvider>
    </ContainerCtx>
  );
}

ReactDOM.render(<Root />, document.getElementById('app'));
