import React, { createContext, useReducer } from 'react';
// this Menu context is to Toggle different menus based on User Clicks

const defaultValue = () => ({
  resources: false,
  mobileNav: false,
  searchBar: false,
  bookmarks: false,
  profile: false,
  notification: false,
  language: false,
});

const ConfigReducer = (state, action) => {
  switch (action.type) {
    case 'set-menu': {
      const inital = defaultValue();
      return Object.assign({}, inital, {
        [action.menu]: !state[action.menu],
      });
    }
    case 'reset-menu': {
      return defaultValue();
    }
    default:
      return state;
  }
};

export const MenuCtx = createContext({});

export default function MenuCtxProvider({ children }) {
  const [state, dispatch] = useReducer(ConfigReducer, defaultValue());
  return (
    <MenuCtx.Provider value={{ state, dispatch }}>{children}</MenuCtx.Provider>
  );
}
