import React from 'react';

// eslint-disable-next-line no-unused-vars
const searchBar = ({ setInput }) => {
  const search = React.useRef(null);
  const handleChange = () => {
    setInput(search.current.value);
  };
  return (
    <React.Fragment>
      <div className="search-holder">
        <div className="search-holder__banner">
          <div className="col-xs-12 text-center hero-header-container">
            <h1 className="hero-header">
              Find the Best Programming Courses &amp; Tutorials
            </h1>
            <div className="search-bar">
              <div className="form-holder">
                <div className="navbarSearch">
                  <input
                    ref={search}
                    onChange={handleChange}
                    className="form-control navbar-search-input js-navbar-search-input nav-input js-filter-topics"
                    type="text"
                    placeholder="Search for the language you want to learn: Python, Javascript,..."
                  />
                </div>
                <img
                  className="icon"
                  src="https://hackr.io/assets/images/header-icons/search-header.svg"
                  width="17"
                  height="17"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default searchBar;
