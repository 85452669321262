import { gql } from 'apollo-boost';

const technologyQuery = gql`
  query technologies($type: String!) {
    technologyList: technologies(type: $type) {
      message
      success
      data {
        _id
        name
        icon
        type
        description
      }
    }
  }
`;

export default technologyQuery;
