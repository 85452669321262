import React from 'react';
import './footer.scss';

const Footer = ({ isLoggedIn }) => {
  if (!isLoggedIn) return <span></span>;
  return (
    <footer>
      <div className="container alt">
        <div className="row">
          <div className="col-xs-12">
            <div className="footer-holder">
              <div className="links">
                <ul className="fx-links js-footer-links">
                  <li>
                    <a href="//about">About Us</a>
                  </li>
                  <li className="programming-tips-link">
                    <a
                      rel="nofollow"
                      href="https://chrome.google.com/webstore/detail/programming-tips/ooaiehbfngcjjeaiedpffeajkeleikpl"
                    >
                      Programming Tips
                    </a>
                  </li>
                  <li>
                    <a href="//help">Help &amp; FAQ</a>
                  </li>
                  <li>
                    <a
                      data-toggle="modal"
                      data-target="#modalFeedback"
                      data-loaded="false"
                      href="/"
                    >
                      We Feedback
                    </a>
                  </li>
                  <li>
                    <a
                      data-toggle="modal"
                      data-target="#modalContact"
                      data-loaded="false"
                      href="/"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a href="//advertise-with-us">Advertise With Us</a>
                  </li>
                  <li>
                    <a href="https://angel.co/company/hackr-io/jobs">
                      We Are Hiring
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
