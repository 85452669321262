/* eslint-disable no-unused-vars */
import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import './header.scss';
import './mobilemenu.scss';

import logo from '../../assets/images/logo.png';
import { ApolloContext } from 'react-apollo';
import navMenuQuery from '../../graphql/query/navbar';
import SubMenu from './partails/submenu';
import RightMenu from './rightMenu';
// import MobileHeader from './mobileMenu';
import { MenuCtx } from '../../HOCs/menuCtx';

const HeaderComponent = ({ isLoggedIn, navMenu, history }) => {
  const [showModel, setShowModel] = useState(false);
  const closeModel = () => {
    setShowModel(false);
  };
  const { state, dispatch } = useContext(MenuCtx);
  const handleClick = useCallback(() => {
    dispatch({
      type: 'reset-menu',
    });
  });
  const menuClick = type => {
    dispatch({
      type: 'set-menu',
      menu: type,
    });
  };

  const LogoClick = () => {
    dispatch({
      type: 'reset-menu',
    });
    history.push('/');
  };

  const [, setActive] = useState(false);

  const showMenu = () => {
    setActive(val => {
      const newval = !val;
      if (newval) {
        document.body.classList.add('open');
      } else {
        document.body.classList.remove('open');
      }
      return newval;
    });
    dispatch({
      type: 'reset-menu',
    });
  };

  useEffect(() => {
    if (state.mobileNav || state.notification || state.profile) {
      document.body.classList.remove('open');
      setActive(false);
    }
  }, [state.mobileNav, state.notification, state.profile]);

  useEffect(() => {
    const node = document.getElementById('jmx-container');
    if (node) {
      node.addEventListener('click', handleClick);
    }
    return () => {
      const node = document.getElementById('jmx-container');
      if (node) {
        node.removeEventListener('click', () => null);
      }
    };
  }, [handleClick]);

  useEffect(() => {
    if (state.notification) {
      document.body.classList.add('open-overlay');
    } else {
      document.body.classList.remove('open-overlay');
    }
    return () => null;
  }, [state.notification]);

  useEffect(() => {
    document.body.classList.remove('open');
  }, []);

  if (!isLoggedIn) {
    return (
      <Fragment>
        <header className="header header-login">
          <div className="page__container">
            <div className="auth-logo">
              <img onClick={handleClick} src={logo} alt="code Logo" />
            </div>
          </div>
        </header>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <header className="header">
        <div className="page__container">
          <div className="left-section">
            <ul className="nav-header-list  navbar-left">
              {navMenu && navMenu.data
                ? navMenu.data.map((i, index) => {
                    return (
                      <li
                        key={index}
                        className={index === 0 ? 'nav-item' : 'nav-item'}
                      >
                        <NavLink
                          activeClassName="active"
                          className="nav-link"
                          to={`/home/${i.name}`}
                        >
                          <span>
                            <img className="nav-icon " src={i.icon} />
                          </span>
                          {i.name}
                        </NavLink>
                      </li>
                    );
                  })
                : ''}
            </ul>
          </div>
          <div className="logo-section">
            <img
              className="hackr-logo-text-path"
              onClick={LogoClick}
              src={logo}
              alt="logo"
            />
          </div>
          <div className="right-section">
            <RightMenu history={history} />
          </div>
        </div>
        <div className="submenu-page__container">
          <div className="submenu__container">
            <ul className="nav-header-list ">
              <div className="nav-content">
                <div className="nav-pane">
                  <SubMenu />
                </div>
              </div>
            </ul>
          </div>
        </div>
      </header>

      <header className="header header-mobile">
        <div className="page__container">
          <div className="nav-bar">
            <button type="button" className="hamburger" onClick={showMenu} />
          </div>
          <div className="logo-section">
            <img
              onClick={LogoClick}
              className="hackr-logo-text-path"
              src={logo}
              alt="logo"
            />
          </div>
          <div className="right-section">
            <RightMenu />
          </div>
        </div>
        <div className="side-menu-left">
          <div className="main-menu">
            <ul className="main-menu__list">
              {navMenu && navMenu.data
                ? navMenu.data.map((i, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          index === 0 ? 'main-menu__item' : 'main-menu__item'
                        }
                      >
                        <NavLink
                          activeClassName="active"
                          className="main-menu__item"
                          to={`/home/${i.name}`}
                        >
                          {i.name}
                        </NavLink>
                      </li>
                    );
                  })
                : ''}
            </ul>
          </div>
        </div>
        <div className="overlay" onClick={showMenu} />
      </header>
      <div className="popup-overlay" onClick={e => handleClick()} />
    </Fragment>
  );
};

function PageWithQuery({ isLoggedIn, history }) {
  const { client } = useContext(ApolloContext);
  const [navMenu, setNavMenu] = useState([]);
  const onResponse = data => {
    setNavMenu(data.navMenu || []);
  };
  const onError = () => {
    // mock Imp
    setNavMenu({
      // @ts-ignore
      data: [
        {
          name: 'programming',
          icon: 'https://hackr.io/assets/images/nav-icons/programming.svg',
        },
        {
          name: 'design',
          icon: 'https://hackr.io/sites/design/icon?ver=1563190142',
        },
        {
          name: 'devops',
          icon: 'https://hackr.io/sites/devops/icon?ver=1563190258',
        },
      ],
    });
  };
  useEffect(() => {
    if (isLoggedIn) {
      client
        .query({
          fetchPolicy: 'no-cache',
          query: navMenuQuery,
        })
        .then(({ data }) => onResponse(data))
        .catch(onError);
    }
    return () => null;
  }, [client, isLoggedIn]);
  return (
    <HeaderComponent
      history={history}
      isLoggedIn={isLoggedIn}
      navMenu={navMenu}
    />
  );
}
const Header = withRouter(PageWithQuery);
export default Header;
