import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavLinkType({ to, children, data, ...rest }) {
  return (
    <NavLink
      {...rest}
      to={{
        pathname: `${to}`,
        state: {
          metaData: data,
        },
      }}
    >
      {children}
    </NavLink>
  );
}
