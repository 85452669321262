/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

const subMenu = () => {
  return (
    <ul className="nav navbar-nav nav-subheader-list js-site-navbar">
      <li>
        <a
          href="/"
          className="navbar-subnav-links  active  site-navigation js-site-navigation"
          data-sub_site="Learn"
        >
          <span className="nav-img">
            <img
              className="nav-icon  "
              src="https://hackr.io/assets/images/nav-icons-new/book.svg"
            />{' '}
            learn
          </span>
        </a>
      </li>
      <li>
        <a
          href="https://io/programming/roadmaps"
          className="navbar-subnav-links  site-navigation js-site-navigation"
          data-sub_site="Roadmaps"
        >
          <span className="nav-img">
            <img
              className="nav-icon   nav-icon-lg"
              src="https://hackr.io/assets/images/nav-icons-new/roadmap.svg"
            />{' '}
            roadmaps
          </span>
        </a>
      </li>
      <li>
        <a
          href="https://io/programming/news"
          className="navbar-subnav-links  site-navigation js-site-navigation"
          data-sub_site="News"
        >
          <span className="nav-img">
            <img
              className="nav-icon  "
              src="https://hackr.io/assets/images/nav-icons-new/news.svg"
            />{' '}
            news
          </span>
        </a>
      </li>
      <li>
        <a
          href="https://io/programming/news"
          className="navbar-subnav-links  site-navigation js-site-navigation"
          data-sub_site="News"
        >
          <span className="nav-img">
            <img
              className="nav-icon"
              src="https://hackr.io/assets/images/nav-icons-new/blog.svg"
            />
            blogs
          </span>
        </a>
      </li>
    </ul>
  );
};

export default subMenu;
