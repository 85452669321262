import { gql } from 'apollo-boost';

const navBarQuery = gql`
  query stacks {
    navMenu: stacks {
      message
      success
      data {
        name
        icon
      }
    }
  }
`;

export default navBarQuery;
