import React, { useContext } from 'react';
import ProfileIcon from '../../assets/images/profile.svg';
import { MenuCtx } from '../../HOCs/menuCtx';
const ProfileBar = ({ history }) => {
  const { state, dispatch } = useContext(MenuCtx);

  const menuClick = type => {
    dispatch({
      type: 'set-menu',
      menu: type,
    });
  };
  const logout = () => {
    document.body.dispatchEvent(new CustomEvent('logout'));
  };
  const navigate = route => {
    history.push({
      pathname: '/profile',
      state: {
        selectedTab: route,
      },
    });
    dispatch({
      type: 'reset-menu',
    });
  };

  return (
    <li className="rightMenu_item">
      <span className="rightMenu_profile">
        <img
          className="rightMenu_img"
          src={ProfileIcon}
          alt="profile-icon"
          onClick={() => menuClick('profile')}
        />
      </span>
      {state.profile && (
        <ul className="rightSubMenu">
          <li className="rightSubMenu_item">
            <span
              role="button"
              tabIndex={-1}
              className="rightSubMenu_link"
              onClick={() => navigate('dashboard')}
            >
              {'My Dashboard'}
            </span>
          </li>
          <li className="rightSubMenu_item">
            <span
              role="button"
              tabIndex={-1}
              className="rightSubMenu_link"
              onClick={() => navigate('submitTutorial')}
            >
              {'Submit Tutorials'}
            </span>
          </li>
          <li className="rightSubMenu_item">
            <span
              role="button"
              tabIndex={-1}
              className="rightSubMenu_link"
              onClick={() => navigate('profile')}
            >
              {'Profile'}
            </span>
          </li>
          <li className="rightSubMenu_item">
            <span
              onClick={() => logout()}
              role="button"
              tabIndex={-1}
              className="rightSubMenu_link"
            >
              {'Logout'}
            </span>
          </li>
        </ul>
      )}
    </li>
  );
};
export default ProfileBar;
