/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { gql } from 'apollo-boost';
import GlobalLoadingIndicator from './components/loader';
import { ApolloContext, Query } from 'react-apollo';
import { Suspense } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  withRouter,
  Route,
  Redirect,
} from 'react-router-dom';
import classNames from 'classnames';
import HomePage from './home/home.js';
import Loading from './helper/loader';
import Header from './components/common/header';
import Footer from './components/common/footer';
import Container from './HOCs/containerCtx';
import { PublicRoute, PrivateRoute } from './helper/routes';
import { ConfigCtx } from './HOCs/configCtx';
import useUserInfo from './hooks/userinfo';
import useUserIdle from './hooks/userIdle';

const ForgotPassword = React.lazy(() => import('./pages/forgot-password'));
const CoursePage = React.lazy(() => import('./pages/CoursePage'));
const UserSignup = React.lazy(() => import('./pages/signup'));
const ProfilePage = React.lazy(() => import('./pages/profilePage'));
const SearchPage = React.lazy(() => import('./pages/search'));
const LoginPage = React.lazy(() => import('./pages/login'));
import PageNotFound from './pages/PageNotFound';

function authRedirect(isLoggedIn) {
  return isLoggedIn ? (
    <Redirect to="/home/programming" />
  ) : (
    <Redirect to="/login" />
  );
}

const RouterChangeCallBack = withRouter(({ callback }) => {
  const status = !!localStorage.getItem('auth-token');
  const { client } = useContext(ApolloContext);

  const logout = () => {
    client.cache.reset();
    localStorage.clear();
    callback(false);
  };

  document.body.classList.remove('open');
  useEffect(() => {
    document.body.addEventListener('logout', logout);
    return () => document.body.removeEventListener('logout', logout);
  }, [logout]);
  callback(status);
  return '';
});

const Routing = ({ loggedIn }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn);
  const [expires] = useUserIdle(isLoggedIn, 5 * 600000);
  // logout on user session expiry -- timeout on UI
  useEffect(() => {
    if (expires) {
      document.body.dispatchEvent(new CustomEvent('logout'));
    }
  }, [expires]);

  function callback(status) {
    setIsLoggedIn(status);
  }
  const mainClass = classNames({
    page: true,
  });
  return (
    <Container>
      <div className={mainClass}>
        <GlobalLoadingIndicator />
        <Suspense fallback={<Loading />}>
          <Router>
            <Header isLoggedIn={true} />
            <Switch>
              <div className="body-container" id="jmx-container">
                <Route exact path="/" component={HomePage} />
                <Route
                  exact
                  isLoggedIn={isLoggedIn}
                  path="/home/:type"
                  component={HomePage}
                />
                <Route
                  exact
                  isLoggedIn={isLoggedIn}
                  path="/search/:searchTxt"
                  component={SearchPage}
                />
                <Route
                  exact
                  isLoggedIn={isLoggedIn}
                  path="/courses/:technologyId"
                  component={CoursePage}
                />
                <Route
                  exact
                  isLoggedIn={isLoggedIn}
                  path="/profile"
                  component={ProfilePage}
                />
                <PublicRoute
                  exact
                  isLoggedIn={isLoggedIn}
                  path="/login"
                  component={LoginPage}
                />
                <PublicRoute
                  exact
                  isLoggedIn={isLoggedIn}
                  path="/register"
                  component={UserSignup}
                />
                <PublicRoute
                  exact
                  isLoggedIn={isLoggedIn}
                  path="/forgetpassword"
                  component={ForgotPassword}
                />
                <PublicRoute
                  exact
                  isLoggedIn={isLoggedIn}
                  path="/resetpassword/:token"
                  component={HomePage}
                />
              </div>
            </Switch>
            <RouterChangeCallBack callback={callback} />
            <Footer isLoggedIn={isLoggedIn} />
          </Router>
        </Suspense>
      </div>
    </Container>
  );
};
const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client(always: true)
  }
`;

export default function PageWithQuery() {
  return (
    <Query query={IS_LOGGED_IN}>
      {({ data: { isLoggedIn }, loading }) => {
        return !loading ? <Routing loggedIn={isLoggedIn} /> : <Loading />;
      }}
    </Query>
  );
}
