import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export function PublicRoute({ component: Component, isLoggedIn, ...rest }) {
  function renderCondition(props) {
    if (isLoggedIn) {
      const { location } = props;
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: location },
          }}
        />
      );
    }
    return <Component {...props} />;
  }
  return <Route {...rest} render={renderCondition} />;
}

export function PrivateRoute({ component: Component, isLoggedIn, ...rest }) {
  function renderCondition(props) {
    if (!isLoggedIn) {
      const { location } = props;
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      );
    }
    return <Component {...props} />;
  }
  return <Route {...rest} render={renderCondition} />;
}
