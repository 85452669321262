/* eslint-disable no-unused-vars */
import React, { Fragment, useContext, useState } from 'react';
import NavLink from '../../packages/elements/navLink';
import ProfileBar from './profileBar';
import SearchIcon from '../../assets/images/search.svg';

import NotificationBar from './notificationBar';
import BookMarkIcon from '../../assets/images/bookmark.svg';
import './rightMenu.scss';
import { MenuCtx } from '../../HOCs/menuCtx';
const RightMenu = ({ history }) => {
  const { state, dispatch } = useContext(MenuCtx);
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const menuClick = type => {
    dispatch({
      type: 'set-menu',
      menu: type,
    });
    if (type === 'bookmakrs') {
      history.push('/bookmarks');
    }
  };

  const resetMenu = () => {
    dispatch({
      type: 'reset-menu',
    });
  };

  const onSearch = event => {
    if (event.charCode === 13) {
      const { target } = event;
      onSearchSubmit(target.value);
    }
  };

  const onSearchChange = event => {
    const val = event.target.value.trim();
    setSearchText(val);
  };

  function onSearchSubmit(value) {
    const val = value.trim();
    if (val && val.length > 0) {
      history.push(`/search/${val}`);
      dispatch({
        type: 'reset-menu',
      });
    }
  }
  return (
    <Fragment>
      {showSearch && (
        <div className="search-container">
          <input
            className="search-box"
            placeholder="Search"
            type="text"
            value={searchText}
            title="Enter the terms you wish to search for."
            onKeyPress={onSearch}
            onChange={onSearchChange}
          />
        </div>
      )}
      <ul className="rightMenu_list">
        <li className="rightMenu_item">
          <span className="rightMenu_profile">
            <img
              className="rightMenu_img"
              src={SearchIcon}
              alt="profile-icon"
              onClick={() => setShowSearch(i => !i)}
            />
          </span>
        </li>
        <ProfileBar history={history} />
        <NotificationBar />
        <li className="rightMenu_item">
          <NavLink to="/bookmarks" className="rightMenu_bookmark">
            <img
              className="rightMenu_img"
              src={BookMarkIcon}
              onClick={() => menuClick('bookmark')}
              alt="bookmark-icon"
            />
          </NavLink>
        </li>
      </ul>
    </Fragment>
  );
};
export default RightMenu;
