import React, { useState, useContext } from 'react';
import './notifications.scss';
import { MenuCtx } from '../../HOCs/menuCtx';
import ClassNames from 'classnames';
import NotificationIcon from '../../assets/images/notification.svg';
// eslint-disable-next-line jsx-a11y/click-events-have-key-events

const NotificationBar = () => {
  const { state, dispatch } = useContext(MenuCtx);
  const [data] = useState([]);

  const menuClick = item => {
    dispatch({
      type: 'set-menu',
      menu: item,
    });
  };

  return (
    <li className="rightMenu_item">
      <span className="rightMenu_profile">
        <img
          className="rightMenu_img"
          src={NotificationIcon}
          alt="profile-icon"
          onClick={() => menuClick('notification')}
        />
      </span>

      <div
        className={ClassNames('side-notification', {
          notify: state.notification,
        })}
      >
        <h5 className="side-notification_title">{'NOTIFICATIONS'}</h5>
        <ul className="side-notification_list">
          {data &&
            data.slice(0, 4).map((i, index) => {
              return (
                <li key={index} className="side-notification_item">
                  <span role="button" tabIndex={-1}>
                    {/* {i.message && i.message.substring(0, 30).concat('...')} */}
                    {i.message}
                  </span>
                </li>
              );
            })}
        </ul>
      </div>
      {/* )} */}
    </li>
  );
};
export default NotificationBar;
